<template>
  <div style="height: 100%">
    <div class="box">
      <div class="main">
        <div class="search">
          <el-button type="primary" @click="addMemberDialog = true"
            >+ 新建成员</el-button
          >
          <div class="search_mid">
            <span>关键字：</span>
            <el-input v-model="queryInfo.Key" placeholder="请输入姓名"></el-input>
          </div>
          <el-button type="primary" @click="inquireSumbintMember">查询</el-button>
          <el-button type="primary" @click="resetSumbintMember">重置</el-button>
        </div>
        <!-- 表格内容 -->
        <div class="primary">
          <el-table
            max-height="660px"
            ref="multipleTable"
            :data="userList"
            tooltip-effect="dark"
            style="width: 100%"
            :header-cell-style="{
            color: '#333333',
            backgroundColor: '#FBFBFD',
          }"
            row-key="id"
            border
            lazy
          >
            <el-table-column type="selection" width="40" align="center">
            </el-table-column>
            <el-table-column
              type="index"
              label="序号"
              min-width="60"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="UserName"
              label="姓名"
              min-width="40"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="Sex"
              label="性别"
              min-width="40"
              align="center"
              :formatter="formatterSex"
            >
            </el-table-column>
            <el-table-column
              prop="PhoneNumber"
              label="手机号"
              min-width="60"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="IdentityCard"
              label="身份证号"
              min-width="100"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="EMail"
              label="邮箱"
              min-width="100"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="HospitalName"
              label="所属医院"
              min-width="150"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="DeptName"
              label="所属科室"
              min-width="80"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="Title"
              label="职称"
              min-width="80"
              align="center"
            >
            </el-table-column>
            <el-table-column label="配置" min-width="80" align="center">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  style="margin-right: 10px;font-size: 16px;color: #3978E7;font-weight: 400;"
                  @click="showEditMember(scope.row)"
                  >编辑</el-button
                >
                <el-button
                  type="text"
                  style="font-size: 16px;color: #3978E7;font-weight: 400;"
                  @click="showDelMember(scope.row)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <div class="block">
            <el-pagination
              background
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="queryInfo.PageIndex"
              :page-sizes="[10,20,30,40]"
              layout="total, sizes, prev, pager, next, jumper"
              :total="memberTotal"
            >
            </el-pagination>
          </div>
        </div>
      </div>
      <!-- 弹出框 -->
      <div class="dialog_add">
        <el-dialog
          :visible.sync="addMemberDialog"
          v-dialogDrag
        >
          <div slot="title" class="header-title">
            <span class="title-name"></span>
            <span class="title-age">新增成员</span>
          </div>
          <div class="body">
            <div class="body-left">
              <el-form label-width="150px" :model="userInfo" :rules="rules">
                <el-form-item label="登录名:" required>
                  <el-input v-model="userInfo.LoginName" placeholder="请输入" required></el-input>
                </el-form-item>
                <el-form-item label="密码:" required>
                  <el-input v-model="userInfo.Password" placeholder="请输入"></el-input>
                </el-form-item>
                <el-form-item label="姓名:" required>
                  <el-input v-model="userInfo.UserName" placeholder="请输入"></el-input>
                </el-form-item>
                <el-form-item label="手机号:" prop="PhoneNumber">
                  <el-input v-model="userInfo.PhoneNumber" placeholder="请输入" style="width: 150px;margin-right: 4px"></el-input>
                  <span style="color: #F56C6C">推荐填写,该项用于接收各类通知</span>
                </el-form-item>
                <el-form-item label="身份证:" prop="IdentityCard">
                  <el-input v-model="userInfo.IdentityCard" placeholder="请输入" @blur="IdBlur"></el-input>
                </el-form-item>
                <el-form-item label="性别:">
                  <el-radio-group v-model="userInfo.Sex">
                    <el-radio :label="1">男</el-radio>
                    <el-radio :label="0">女</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-form>
            </div>
            <div class="body-right">
              <el-form label-width="150px">
                <el-form-item label="微信:">
                  <el-input v-model="userInfo.WX" placeholder="请输入"></el-input>
                </el-form-item>
                <el-form-item label="邮箱:">
                  <el-input v-model="userInfo.EMail" placeholder="请输入" @blur="emailVal"></el-input>
                </el-form-item>
                <el-form-item label="职称:">
                  <el-select  placeholder="请选择" v-model="userInfo.Title">
                    <el-option :label="item.Name" :value="item.Name" v-for="(item,index) in userTitleOptions" :key="index"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="所属医院:" required>
                  <el-autocomplete
                    class="inline-input"
                    v-model="addstate"
                    value-key="HospitalName"
                    :fetch-suggestions="addUserQuerySearchAsync"
                    placeholder="请输入内容"
                    :trigger-on-focus="false"
                    @select="addMemberhandleSelect"
                    clearable
                  ></el-autocomplete>
                </el-form-item>
                <el-form-item label="科室:" required>
                  <el-select v-model="userInfo.DepartmentId" placeholder="请选择" filterable>
                    <el-option
                      :label="item.DeptName"
                      :value="item.Id"
                      v-for="(item, index) in DepartmentOptions"
                      :key="index"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="角色:">
                  <el-select v-model="userInfo.RoleId" placeholder="请选择">
                    <el-option
                      :label="item.RoleName"
                      :value="item.Id"
                      v-for="(item, index) in option"
                      :key="index"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-form>
            </div>
          </div>
          <div class="btn">
            <button  @click="addMemberDialog = false">取消</button>
            <button @click="addMemberInfo">确定</button>
          </div>
        </el-dialog>
      </div>
      <div class="dialog_add">
        <el-dialog
          :visible.sync="editMemberDialog"
          v-dialogDrag
        >
          <div slot="title" class="header-title">
            <span class="title-name"></span>
            <span class="title-age">编辑成员</span>
          </div>
          <div class="body">
            <div class="body-left">
              <el-form label-width="150px">
                <el-form-item label="登录名:">
                  <el-input v-model="editForm.LoginName"></el-input>
                </el-form-item>
                <el-form-item label="密码:">
                  <el-input v-model="editForm.Password"></el-input>
                </el-form-item>
                <el-form-item label="姓名:">
                  <el-input v-model="editForm.UserName"></el-input>
                </el-form-item>
                <el-form-item label="手机号:">
                  <el-input v-model="editForm.PhoneNumber"></el-input>
                </el-form-item>
                <el-form-item label="身份证:">
                  <el-input v-model="editForm.IdentityCard"></el-input>
                </el-form-item>
                <el-form-item label="性别:">
                  <el-radio-group v-model="editForm.Sex">
                    <el-radio :label="1">男</el-radio>
                    <el-radio :label="0">女</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-form>
            </div>
            <div class="body-right">
              <el-form label-width="150px">
                <el-form-item label="微信:">
                  <el-input v-model="editForm.WX"></el-input>
                </el-form-item>
                <el-form-item label="邮箱:">
                  <el-input v-model="editForm.EMail"></el-input>
                </el-form-item>
                <el-form-item label="职称:">
                  <el-input v-model="editForm.Title" placeholder=""></el-input>
                </el-form-item>
                <el-form-item label="所属医院:">
                  <el-input v-model="editForm.HospitalName" disabled></el-input>
                </el-form-item>
                <el-form-item label="科室:">
                  <el-input v-model="editForm.DeptName" disabled></el-input>
                </el-form-item>
                <el-form-item label="角色:">
                  <el-select v-model="RoleId" placeholder="请选择">
                    <el-option
                      :label="item.RoleName"
                      :value="item.Id"
                      v-for="(item, index) in option"
                      :key="index"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-form>
            </div>
          </div>
          <div class="btn">
            <button  @click="editMemberDialog = false">取消</button>
            <button @click="submintEditUser">确定</button>
          </div>
        </el-dialog>
      </div>
      <!--    删除成员弹框-->
      <div class="systemsetting">
        <el-dialog
          top="35vh"
          :visible.sync="delUserdialog"
          width="510px"
          v-dialogDrag
        >
          <div slot="title" class="header-title">
            <span class="title-name"></span>
            <span class="title-age">提示</span>
          </div>
          <div style="text-align: center">
            <i class="el-icon-warning" style="color: #FFBA00;font-size: 24px ;margin-right: 5px"></i>
            <span style="font-size: 18px;color: #666">此操作将永久删除该成员，是否继续？</span>
          </div>
          <div class="btn">
            <button  @click="delUserdialog = false">取消</button>
            <button @click="submitDelUser">确定</button>
          </div>
        </el-dialog>
      </div>
    </div>
  </div>
</template>
<script>
import {
  getUserRolesInfo,
  getDepartmentInfo,
  getHospitalList,
  getUserInfo,
  getUser,
  addUser,
  editUser,
  delUser,
  GetRolesList,
  getUsersInfo,
  getTitleInfo
} from "@/api/project.js";
import { mapMutations } from "vuex";
import verification from "@/utils/verification";
import research from "@/api/research";
import api from "@/api/url";
export default {
  data() {
    return {
      bdId: "",
      saveRoleId: "", //保存角色id做对比
      RoleId: "", // 编辑角色id
      PurchaseId: window.sessionStorage.getItem('PurchaseId'),
      Type: window.sessionStorage.getItem('Type'),
      delUserdialog: false, // 删除成员弹框
      rules: {
        PhoneNumber: [
          { required: false, validator: verification.validatePhone, trigger: 'blur' }],
        IdentityCard: [{ validator: verification.validateIdCard, trigger: 'blur' }]
      },
      input: "",
      option:[],//角色数组
      userTitleOptions: [],
      editRoleValue: "",
      routename: "", //从什么页面进来的组件name值。
      editSexValue: "",
      radioSex: "",
      radioRole: "",
      checkRoleValue: "",
      editInnerVisible: false,
      outerVisible: false,
      addInnerVisible: false,
      DepartmentOptions: [],
      checkList: [],
      userRolesList: [],
      queryInfo: {
        key:"",
        PageIndex: 1,
        PageSize: 10,
      },
      userQueryInfo: {
        Key: "",
        PageIndex: 0,
        PageSize: 15,
      },
      Key: "",
      PageIndex: 0,
      PageSize: 8,
      memberTotal: 0,
      userValue: "",
      typeValue: "",
      deptValue: "",
      item: {},
      restaurants: [],
      unitstate: "",
      addstate: "",
      editstate: "",
      timeout: null,
      dialogImageUrl: "",
      dialogVisible: false,
      disabled: false,
      url: "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
      userInfo: {
        IdentityCard: "",
        LoginName: "",
        UserName: "",
        NickName: "",
        Sex: 1,
        Title: "",
        PhoneNumber: "",
        Password: "",
        WX: "",
        QQ: "",
        EMail: "",
        RoleId: "",
        HospitalId: "",
        DepartmentId: "",
      },
      editForm: {},
      addForm: {
        ProjectUsers: [
          {
            HospitalId: "",
            DepartmentId: "",
            RoleId: "",
            Name: "",
            Tellphone: "",
            AccountName: "",
            Password: "",
            JobTitle: "",
            FacePhoto: "",
          },
        ],
      },
      HospitalAllList: [],
      userList: [],
      hospitalData: [],
      addMemberDialog: false,
      editMemberDialog: false,
      editDialogVisible: false,
    };
  },
  components: {
  },
  created() {
    this.getUserTitleList();
    this.allrolelist();
    this.getUserList();
    this.getSystemRoleList();
  },
  mounted() {
    this.getHospital("");
  },
  methods: {
    allrolelist(){//角色
      api.allroleData().then(res=>{
        if(res.data.Status==1){
          let arr = [];
          res.data.Entity.forEach((el) => {
            if (el.SysScope == 1) {
              arr.push(el);
            }
          });
          this.option= arr
          console.log(res,'角色列表')
        }else{
          this.$message.error(res.data.Message)
        }
      })
    },
    phoneVal() {
      const phoneReg = /^1[3456789]\d{9}$/
      if (!phoneReg.test(this.userInfo.PhoneNumber)) {
        this.$message.error('手机格式不正确')
        return false
      }
    },
    emailVal() {
      const regEmail = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+((\.[a-zA-Z0-9_-]{2,3}){1,2})$/;
      if (this.userInfo.EMail == "") {
          console.log('邮箱')
      } else {
        if (!regEmail.test(this.userInfo.EMail)) {
          this.$message.error('邮箱格式不正确')
          this.userInfo.EMail = ""
          return false
        }
      }
    },
    IdBlur() {
      const regId = /^(^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$)|(^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])((\d{4})|\d{3}[X])$)$/;
      if (!regId.test(this.userInfo.IdentityCard)) {
        console.log('身份证不正确')
      } else {
        if (this.userInfo.IdentityCard.substr(16, 1)% 2 == 1){
          this.userInfo.Sex = 1
        } else {
          this.userInfo.Sex = 0
        }
      }
    },
    inquireSumbintMember() {
      console.log("+++");
      this.getUserList();
    },
    resetSumbintMember() {
      this.queryInfo.Key = "";
      this.getUserList();
    },
    userTitleSelect(value) {
      console.log(value);
      this.userInfo.Title = value.Name;
    },
    async getUserTitleList() {
      const parame = {};
      parame.type = "JobTitle";
      let res = await getTitleInfo(parame);
      console.log(res);
      this.userTitleOptions = res.data.Entity;
    },
    checkRoleSelect(value) {
      console.log(value);
      this.userInfo.RoleId = value.Id;
    },
    async getSystemRoleList() {
      let res = await GetRolesList();
      console.log(res);
      this.tableData = res.data.Entity;
    },
    ...mapMutations(["routestorage"]),
    editSexStatus(value) {
      console.log(value);
      this.editForm.Sex = value;
      console.log(this.editForm);
    },

    // 新增成员框科室选择控制
    handleChange(value) {
      console.log(value);
    },

    // 获取成员列表
    async getUserList() {
      var obj= {
        Key: this.queryInfo.Key,
        PageIndex: this.queryInfo.PageIndex-1,
        PageSize: this.queryInfo.PageSize,
      };
      const res = await getUser(obj);
      console.log(res);
      if (res.data.Status !== 1)
        return this.$message.error("获取用户列表失败！");
      this.userList = res.data.Entity;
      this.memberTotal = res.data.TotalRowsCount;
    },
    //新增成员
    async addMemberInfo() {
      const phoneReg = /^1[3456789]\d{9}$/
      if (this.userInfo.PhoneNumber == "") {
        console.log(this.userInfo.PhoneNumber)
      } else {
        if (!phoneReg.test(this.userInfo.PhoneNumber)) {
          this.$message.error('手机格式不正确')
          return false
        }
      }
      if (this.userInfo.Password == "") {
        this.$message.error("请输入6-20位的密码")
        return false
      }
      const parame = this.userInfo;
      let res = await addUser(parame);
      console.log(res,this.userInfo.RoleId,'角色');

      if (res.data.Status == 1) {
        if (this.userInfo.RoleId) {
          let obj={
            TopicId:0,
            RoleId:this.userInfo.RoleId,
            UserId:res.data.NewID,
          }
          api.adduserData(obj).then(res=>{
            if (res.data.Status==1) {
              console.log(res.data.Message)
            } else {
              this.$message.error(res.data.Message)
            }
          })
        }
        this.$message.success("添加成员成功！");
        this.addMemberDialog = false;
        this.getUserList();
        this.checkRoleValue = "";
        this.userValue = "";
        this.addstate = "";
        this.radioSex = ""
        this.userInfo={
            IdentityCard: "",
            LoginName: "",
            UserName: "",
            NickName: "",
            Sex: 1,
            Title: "",
            PhoneNumber: "",
            Password: "",
            WX: "",
            QQ: "",
            EMail: "",
            RoleId: "",
            HospitalId: "",
            DepartmentId: ""}
      } else if (res.data.Status == -1) {
        this.$message.error(res.data.Message);
      }
    },
    // 项目基本信息页面科室搜索
    async addProjecthandleSelect(item) {
      this.addForm.ProjectUsers.HospitalId = item.Id;
      console.log(item);
      var ratio = {};
      ratio.HospitalId = item.Id;
      let arr = await getDepartmentInfo(ratio);
      console.log(arr);
      this.DepartmentOptions = arr.data.Entity;
      console.log(this.DepartmentOptions);
    },
    async querySearchAsync(queryString, cb) {
      console.log(queryString);
      let arr = await this.getHospital(queryString);
      console.log(arr);
      cb(this.restaurants);
    },
    // 单位维护页面医院添加
    unithandleSelect(item) {
      console.log(item);
      var isBoole = true;
      this.hospitalData.forEach((element) => {
        if (element.Id == item.Id) {
          isBoole = false;
        }
      });
      if (isBoole) {
        this.hospitalData.push(item);
      }
      console.log(this.hospitalData);
    },

    // 项目成员维护页面新增成员框医院搜索
    async getHospital(keyValue) {
      let params = { key: keyValue };
      let res = await getHospitalList(params);
      if (res.data.Status == 1) {
        res.data.Entity.forEach((element) => {
          element.value = element.HospitalName;
        });
        this.restaurants = res.data.Entity;
      } else {
        this.restaurants = [];
      }
      return this.restaurants;
    },

    async addUserQuerySearchAsync(queryString, cb) {
      console.log(queryString);
      let arr = await this.getHospital(queryString);
      console.log(arr);
      cb(this.restaurants);
      console.log(this.restaurants);
    },

    // 项目成员维护页面新增成员框科室搜索
    async addMemberhandleSelect(item) {
      this.userInfo.HospitalName = item.HospitalName;
      this.userInfo.HospitalId = item.Id;
      console.log(item);
      var ratio = {};
      ratio.HospitalId = item.Id;
      let arr = await getDepartmentInfo(ratio);
      this.DepartmentOptions = arr.data.Entity;
      console.log(this.DepartmentOptions);
    },

    // 科室名称选择
    currentSelect(value) {
      const obj = this.DepartmentOptions.find((el) => el.Id === value);
      this.userInfo.DeptName = obj.DeptName;
      this.userInfo.DepartmentId = obj.Id;
    },

    // 编辑成员页医院选择框
    editMemberhandleSelect(item) {
      console.log(item);
    },
    // 监听pagesize（每页显示多少条数据）改变的事件
    handleSizeChange(newSize) {
      this.queryInfo.PageSize = newSize;
      this.getUserList();
    },
    // 监听pagenum（页码值）改变的事件
      handleCurrentChange(newPage) {
      this.queryInfo.PageIndex = newPage;
      this.getUserList();
    },
    // 编辑成员
    async showEditMember(item) {
      console.log(item);
      const parame = { UserId: item.Id };
      const res = await getUsersInfo(parame);
      console.log(res,'编辑');
      if (res.data.Status == 1) {
        this.editForm = res.data.Entity;
        this.editMemberDialog = true;
        if (res.data.Entity.RoleId !==null) {
          this.bdId = res.data.Entity.TopicRoleId
          this.RoleId = res.data.Entity.RoleId
          this.saveRoleId = res.data.Entity.RoleId
        } else {
          this.RoleId = ""
          this.saveRoleId = ""
          this.bdId = 0
        }
        // var obj = {
        //   TopicId: 0,
        //   UserId: res.data.Entity.UserId
        // }
        // research.listRole(obj).then(res => {
        //   console.log(res,'编辑角色的对象')
        //   if (res.data.ExtraData !== null) {
        //     this.bdId = res.data.ExtraData.Id
        //     this.RoleId = res.data.ExtraData.RoleId
        //     this.saveRoleId = res.data.ExtraData.RoleId
        //   } else {
        //
        //   }
        // })
      } else {
        this.$message.error(res.data.Message)
      }
    },
    // 编辑成员信息并提交
    async submintEditUser() {
      console.log(this.editForm.Id,'编辑提交的ID')
      const res = await editUser(this.editForm);
      console.log(res);
      if (res.data.Status == 1) {
        if (this.RoleId != this.saveRoleId ) {
          let obj={
            Id: this.bdId,
            TopicId:0,
            RoleId:this.RoleId,
            UserId:this.editForm.Id,
          }
          research.updateRole(obj).then(res => {
            if (res.data.Status == 1) {
              console.log(res,'编辑角色成功')
            } else {
              this.$message.error(res.data.Message)
            }
          })
        }
        this.$message.success("更新信息成功！");
        this.editMemberDialog = false;
        this.getUserList();
      } else {
        this.$message.error(res.data.Message)
      }
    },
    submitDelUser() { // 删除成员
      delUser([this.delUserItem.Id]).then(res=>{
        if(res.data.Status==1){
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
          this.delUserdialog = false
          this.getUserList();
        } else {
          this.$message.error(res.data.Message)
        }
      })
    },
    // 删除成员
    showDelMember(item) {
      this.delUserItem = item
      this.delUserdialog = true
    },

    formatterSex(row) {
      if (row.Sex == 1) {
        return "男";
      }
      return "女";
    },
  },
  beforeRouteEnter(to, from, next) {
    //获取从什么页面进来的路由name值
    next((vm) => {
      vm.routename = from.name;
      // 通过 `vm` 访问组件实例
    });
  },
};
</script>

<style lang='scss' scoped>
@import "../../styles/mixin.scss";
.box {
  overflow: hidden;
  position: absolute;
  //top: 0;
  width: 100%;
  /deep/.el-breadcrumb__inner a,
  /deep/.el-breadcrumb__inner.is-link {
    font-size: 15px;
    color: #00f6ff;
    cursor: pointer;
  }
  /deep/.el-breadcrumb__item:last-child/deep/.el-breadcrumb__inner:hover {
    color: #00f6ff;
    cursor: pointer;
  }

  .main {
    //border: 1px solid #00f6ff;
    border-top: 0;
    height: 80%;
    margin: 0 20px;
    .search {
      display: flex;
      align-items: center;
      padding: 10px;
      width: 98%;
      margin: 0 auto;
      margin-top: 20px;
      height: 25px;
      line-height: 25px;
      //color: #00f6ff;
      .search_mid {
        margin: 0 20px;
        span {
          display: inline-block;
          margin: 10px 5px;
          color: #666666;
          @include add-size($font_size_16);
        }
        .el-input {
        width: 150px;
        height: 40px;
        display: inline-block;
        /deep/.el-input__inner {
          @include add-size($font_size_16);
          height: 40px;
        }
      }

      }
    .el-button {
          //font-size: 13px;
          //padding: 5px 15px;
      //margin-bottom: 10px;
      //margin-top: 20px;
        }
    }
    .primary {
      /* //element-ui table的去除右侧滚动条的样式 */
      ::-webkit-scrollbar {
        width: 2px;
        height: 1px;
      }
      /* // 滚动条的滑块 */
      ::-webkit-scrollbar-thumb {
        background-color: #00f6ff;
        border-radius: 0px;
      }
      height: 80%;
      width: 98%;
      overflow: hidden;
      border-color: #031c65;
      margin: 20px 20px;
      /deep/ .view {
        color: #fff;
      }
      /deep/ .related {
        color: #fff;
      }
      /deep/.el-table::before {
        //background-color: #031c65;
      }
      /deep/.el-table {
        // border-color: #031c65
        border: 0;
        color: #00f6ff;
        background-color: transparent;
      }
      /deep/ .el-table tr {
        border: 0;
        color: #606266;
      }
      /deep/ .el-table th {
        padding: 8px 0;
      }
      /deep/ .el-table--enable-row-transition .el-table__body td,
      .el-table .cell,
      .el-table__body {
        color: #606266!important;
        .el-button {
          background-color: transparent;
          border: 0;
        }
      }
      .block {
        position: fixed;
        bottom: 15px;
        .left {
          margin-left: 20px;
          height: 32px;
          line-height: 32px;
          float: left;
          span {
            font-weight: normal;
            //  color: #07c5dc;
            color: #fff;
          }
          i {
            font-style: normal;
          }
        }
        .el-pagination {
          float: left;
        }
      }

      .el-table {
        /deep/td {
          padding: 5px 0;
          /deep/.el-table-column--selection .cell {
            padding-left: 0;
          }
        }

        /deep/.cell {
          padding-left: 5px;
          .el-button + .el-button {
            margin-left: 0;
          }
          .el-button--mini {
            padding: 5px 5px;
          }
        }
      }
    }
  }
}
.dialog_add {
  /deep/ .el-dialog {
    width: 1200px;
    border-radius: 10px;
    .el-dialog__header {
      border-radius: 10px 10px 0px 0px;
      background: #3388ff;
      .header-title {
        .title-name {
          display: inline-block;
          width: 10px;
          height: 10px;
          border: 4px solid #ffffff;
          border-radius: 50%;
          vertical-align: middle;
          margin-right: 4px;
          margin-bottom: 5px;
        }
        .title-age {
          vertical-align: top;
          display: inline-block;
          @include add-size($font_size_16);
          color: #ffffff;
        }
      }
      .el-dialog__headerbtn .el-dialog__close {
        color: #fff;
      }
    }
    .body {
      height: 430px;
      .body-left {
        float: left;
        .el-form {
          .el-form-item {
            .el-form-item__label {
              @include add-size($font_size_16);
              color: #666666;
            }
            .el-input {
              @include add-size($font_size_16);
              width: 390px;
            }
          }
        }
      }
      .body-right {
        float: left;
        .el-form {
          .el-form-item {
            .el-form-item__label {
              @include add-size($font_size_16);
              color: #666666;
            }
            .el-input {
              @include add-size($font_size_16);
              width: 390px;
            }
            .el-icon-circle-plus-outline {
              width: 30px;
              display: inline-block;
              font-size: 30px;
              vertical-align: middle;
            }
            .pspan {
              display: inline-block;
              width: 360px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              vertical-align: middle;

              .spanname {
                @include add-size($font_size_16);
                margin-left: 6px;
              }
            }
            .block {
              .el-date-editor--daterange.el-input,
              .el-date-editor--daterange.el-input__inner,
              .el-date-editor--timerange.el-input,
              .el-date-editor--timerange.el-input__inner {
                width: 390px;
              }
            }
            .el-radio__label{
              @include add-size($font_size_16);
            }
          }
        }
      }
    }
    .btn {
      margin-bottom: 14px;
      text-align: center;
      button:nth-child(1) {
        width: 180px;
        background: #ffffff;
        border: 1px solid #3388ff;
        height: 50px;
        border-radius: 10px;
        font-size: 24px;
        color: #3388ff;
        margin-right: 20px;
      }
      button:nth-child(2) {
        width: 180px;
        border: 1px solid #3388ff;
        background: #3388ff;
        height: 50px;
        border-radius: 10px;
        font-size: 24px;
        color: #fff;
      }
    }
  }
}
.systemsetting /deep/.el-dialog {
  border-radius: 10px;
  .el-dialog__header {
    border-radius: 10px 10px 0px 0px;
    background: #3388ff;
    margin-bottom: 20px;
    .header-title {
      .title-name {
        display: inline-block;
        width: 10px;
        height: 10px;
        border: 4px solid #ffffff;
        border-radius: 50%;
        vertical-align: middle;
        margin-right: 4px;
        margin-bottom: 5px;
      }
      .title-age {
        vertical-align: top;
        display: inline-block;
        @include add-size($font_size_16);
        color: #ffffff;
      }
    }
    .el-dialog__headerbtn .el-dialog__close {
      color: #fff;
    }
  }
  .btn {
    margin-top: 52px;
    text-align: center;
    button:nth-child(1) {
      width: 180px;
      background: #ffffff;
      border: 1px solid #3388ff;
      height: 50px;
      border-radius: 10px;
      font-size: 24px;
      color: #3388ff;
      margin-right: 20px;
    }
    button:nth-child(2) {
      width: 180px;
      //background: #ffffff;
      border: 1px solid #3388ff;
      background: #3388ff;
      height: 50px;
      border-radius: 10px;
      font-size: 24px;
      color: #fff;
    }
  }
}
/deep/.el-select{
  .el-icon-circle-close {
    background-color: #606266;
  }
}
.primary /deep/.el-table th {
  @include add-size($font_size_16);
  vertical-align: middle;
}
.primary /deep/.el-table__row {
  @include add-size($font_size_16);
  vertical-align: middle;
}
</style>
